.portfolio-main-area-wrap {
  padding: 70px 0;
  padding-top: 0px;
  @media (max-width: 767px) {
    display: none;
  }
  .portfolio-header {
    padding-bottom: 0px;
    h2 {
      margin-bottom: 30px;
    }
  }
  .portfolio-items-wrap {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-wrap: wrap;
    .swiper-pagination {
      bottom: -5px !important;
    }
    .swiper-wrapper {
      padding: 70px 0;
      min-height: 500px;
      padding-bottom: 0;
      .swiper-slide {
        &:nth-child(even) {
          position: relative;
          top: 40px;
        }
      }
    }
    .swiper-button-next {
      position: absolute;
      top: 0;
      justify-content: flex-end;
      width: unset;
      top: 20px;
      z-index: 999;
      margin-left: 4px;
      &::after {
        font-size: 13px;
        font-weight: 700;
        width: 30px;
        height: 30px;
        background: #1b3f73;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 4px;
      }
    }
    .swiper-button-prev {
      position: absolute;
      top: 20px;
      justify-content: flex-end;
      width: 100%;
      font-size: 15px;
      padding-right: 56px;

      &::after {
        font-size: 13px;
        font-weight: 700;
        width: 30px;
        height: 30px;
        background: #1b3f73;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 4px;
      }
    }
    .swiper.swiper-initialized {
      height: 100%;
      span.swiper-pagination-bullet {
        height: 2px;
        width: 24px;
        opacity: 0.3;
        background: #1b3f73;
        border-radius: 4px;
        &.swiper-pagination-bullet-active {
          width: 30px;
          opacity: 1;
          transition: 0.3s;
        }
      }
    }
    .portfolio-single-item {
      // width: 23%;
      border-radius: 10px;
      transition: 0.5s;
      background: #fff;
      min-height: 390px;
      // box-shadow: 0px 10px 30px rgb(182 182 182 / 15%);
      &:hover {
        box-shadow: 0px 10px 30px rgb(182 182 182 / 25%);
        transition: 0.3s;
        margin-top: -5px;
      }
      // &:nth-child(even) {
      //   position: relative;
      //   top: 40px;
      // }
      .portfolio-single-item-bottom-content {
        padding: 10px 15px;
        h4 {
          margin-bottom: 12px;
          font-size: 22px;
          color: #333333;
        }
      }
      img {
        width: 100%;
        display: inline-block;
        margin-bottom: 15px;
      }
    }
    .hero-button-bottom-wrap {
      display: flex;
      justify-content: center;
      width: 100%;
      // margin-top: 85px;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: #b2beb5;
}
