.footer-main-area-wrap {
  background: #1b3f73;
  padding: 15px 0;
  p {
    margin-bottom: 0;
    color: #fff;
  }
  .footer-inner-area-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-right-social-area {
      display: flex;
      gap: 10px;
      align-items: center;
      a {
        display: block;
        color: #fff;
        font-size: 22px;
      }
    }
  }
}
