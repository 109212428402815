//==============================
.experiance-main-area-wrap {
  margin-bottom: 20px;
  .experiance-section-title.common-title {
    margin-bottom: 40px;
    p {
      margin-top: 30px;
    }
  }
  .experiance-inner-area-wrap {
    .single-experiance-card-wrap {
      border: 1px solid rgba(208, 208, 208, 0.3294117647);
      border-radius: 10px;
      overflow: hidden;
      background: #fff;
      min-height: 430px;
      box-shadow: 0px 0px 3px 0px #efefef;
      .single-experiance-card-header {
        background: #e2eaf4;
        height: 125px;
        display: flex;
        padding: 15px;
        align-items: center;
        gap: 15px;
        .experiance-left-logo {
          img {
            width: 70px;
          }
        }
        .experiance-right-details {
          h4 {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 8px;
          }
          p {
            margin-bottom: 0;
            font-size: 14px;
            font-family: "Myriad Pro Bold";
            color: #333333;
            line-height: 20px;
          }
          span {
            font-size: 12px;
            font-family: "Myriad Pro Semibold";
          }
        }
      }
      .single-experiance-card-body {
        padding: 18px 15px;
        .hr-line-dec-area hr {
          margin: 10px auto;
        }
        .experiace-dec {
          // padding-bottom: 10px;
          // border-bottom: 1px solid #efefef;
          min-height: 40px;
          p {
            svg {
              width: 16px;
              path {
                stroke: #eb2027;
              }
            }
          }
          &.first-dec-area-height {
            span.experience-title {
              color: #1b3f73;
              font-size: 18px;
              display: block;
              margin-bottom: 3px;
              line-height: 24px;
              font-family: "Myriad Pro Bold";
            }
          }
          p {
            font-size: 12px;
            font-family: "Myriad Pro Regular";
            color: #000;
            display: flex;
            align-items: center;
            gap: 6px;
            b {
              font-weight: bold;
              color: #000;
              font-family: "Myriad Pro Bold";
            }
          }
          span {
            font-size: 14px;
            font-weight: 700;
            color: #000;
            display: block;
          }
        }
        ul li {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 7px;
        }
      }
    }

    .slick-list {
      margin: 0 -10px;
    }
    .slick-slide > div {
      padding: 0 10px;
    }
    .arrow-icon-prev {
      position: absolute;
      z-index: 9;
      top: 42%;
      left: -50px;
      @media (max-width: 991px) {
        top: unset;
        bottom: -40px;
        left: 42%;
      }
      @media (max-width: 576px) {
        top: unset;
        bottom: -40px;
        left: 38%;
      }
    }
    .arrow-icon-next {
      position: absolute;
      z-index: 9;
      top: 42%;
      right: -50px;
      @media (max-width: 991px) {
        top: unset;
        bottom: -40px;
        right: 42%;
      }
      @media (max-width: 576px) {
        top: unset;
        bottom: -40px;
        right: 38%;
      }
    }
  }
}
