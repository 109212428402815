.header-main-area-wrap {
  background: #1b3f73;
  position: sticky;
  top: -2px;
  z-index: 9999;
  padding: 3px 0;
  box-shadow: 0px 0px 20px 1px rgb(49 49 49 / 60%);
  &:after {
    // content: "";
    // width: 100%;
    // height: 4px;
    // /* background: linear-gradient(180deg, white, transparent); */
    // position: absolute;
    // bottom: 0px;
    // left: 0;
    // box-shadow: 0px 0px 30px 3px #fff;
  }
  .header-top-main-area {
    padding: 10px 0;
    padding: 10px 0;
    padding-bottom: 5px;
    ul {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      gap: 6vw;
      margin-bottom: 0;
      padding: 10px 0;
      padding-bottom: 0;
      li {
        font-size: 12px;
      }
    }
  }
  .header-navbar-items-area {
    padding: 10px 0;
    ul {
      list-style: none;
      padding-left: 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      // gap: 6vw;
      margin-bottom: 0;
      padding: 10px 0;
      // padding-top: 0;
      li {
        color: #fff;
        width: 15%;
        // border: 2px solid #fff;
        text-align: center;
        padding: 10px 15px;
        border-right: none;
        &:last-child {
          // border-right: 2px solid #fff;
        }
        &.desktop-main-logo {
          h3 {
            font-size: 24px;
            line-height: 32px;
            margin: 0;
          }
        }
      }
    }
    .responsive-nav-items {
      // display: none;
    }
  }
}
.header-bottom-area-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-logo-area-left {
  // display: none;
  h4 {
    margin-bottom: 0;
    color: #fff;
  }
  img {
    height: 40px;
  }
}
button.nav-toggle-menu {
  border: none;
  background: none;
  width: 24px;
  height: 24px;
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &.show-nav-button {
    position: relative;
    transform: rotate(45deg);
    transition: 0.5s;
    span {
      transition: 0.5s;
      margin-bottom: 0;
      position: absolute;
      left: 0;
      &:nth-child(1) {
        margin-bottom: 0;
        transform: rotate(90deg);
      }
      &:nth-child(3) {
        transform-origin: center center;
        transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);

        transform: rotate(180deg);
      }
      &:nth-child(2) {
        display: none;
        transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
  }
  span {
    width: 100%;
    height: 2px;
    display: block;
    background: #fff;
    // margin-bottom: 6px;
    transition: 0.5s;
    &:last-child {
      margin-bottom: 0;
      // transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }
  .hide-nav-button {
    span {
      transition: 0.5s;
    }
  }
}
.nav-toggle-button-wrap {
  // display: none;
}
.responsive-nav-items.nav-toggle-menu-hide {
  display: none;
}
.responsive-nav-items {
  position: absolute;
  left: 0;
  width: 100%;
  display: block !important;
  background: #fff;
  top: 50px;
  ul {
    display: block !important;

    li {
      text-align: center !important;
      width: 100% !important;

      text-align: center;
      padding: 10px 15px;
      border-right: none;
      margin-bottom: 15px;
      font-weight: 500;
      a {
        color: #000 !important;
        cursor: pointer;
        position: relative;
        &:after {
          content: "";
          width: 0%;
          height: 3px;
          position: absolute;
          background: #ddd;
          left: 0;
          bottom: -4px;
          border-radius: 5px;
          transition: 0.3s;
        }
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
  &.nav-toggle-menu-hide {
    transform: rotateX(90deg);
    transform-origin: top;
    transition: 0.3s;
  }
  &.nav-toggle-menu-show {
    transform: rotateX(0deg);
    transform-origin: top;
    transition: 0.3s;
  }
}
