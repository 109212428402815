@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Manrope:wght@300;400;500;600&display=swap");
@import "node_modules/react-modal-video/scss/modal-video.scss";
@import "assets/scss/style.scss";
body {
  font-family: "Myriad Pro Regular";

  background: #ffffff;
  a {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }

  .shape-from-right {
    position: fixed;
    right: 0;
    top: 20%;
    z-index: -1;
  }
  .case-studies-main-page-wrap {
    .stories-left {
      display: none;
    }
  }
  .modal-main-area-wrap {
    z-index: 99999;
    padding-left: 0 !important;
  }
}
// .slick-slider {
//   z-index: 99;
//   height: 90vh;
//   * {
//     height: 100%;
//   }
// }
