.success-stories-inner-area {
  padding: 70px 0;
  padding-bottom: 0;
  .success-stories-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    flex-wrap: wrap;
    gap: 2vw;
    .stories-title-left h2 {
      // margin-bottom: 10px;
    }
  }

  .success-stories-lists-area-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;

    .success-story-single-item {
      width: 32%;
      border: 1px solid #dddddd;
      border-radius: 10px;
      padding: 25px;
      min-height: 400px;
      position: relative;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #fff;
      overflow: hidden;
      // p {
      //   color: #999999;
      // }
      a {
        text-decoration: none;
        position: relative;
        z-index: 999;
        cursor: pointer;
      }
      .maac-portfolio-btn {
        transform: scale(0);
        transform-origin: left;
        transition: 0.2s;
      }
      &:hover {
        .maac-portfolio-btn {
          transform: scale(1);
          transform-origin: left;
        }
      }
      div > h5 {
        font-size: 22px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 15px;
      }
      > p {
        font-size: 16px;
        margin-bottom: 40px;
        color: #999999;
        line-height: 24px;
      }
      .story-index {
        // position: relative;
        h3 {
          position: absolute;
          bottom: 0 !important;
          line-height: 1;
          left: 0 !important;
          margin-bottom: 0 !important;
          opacity: 1 !important;
          svg {
            opacity: 0.75;
          }
        }
      }
      .success-story-item-header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 30px;

        > h3 {
          font-family: "Manrope", sans-serif;
          color: #9e9e9e;
        }
        svg {
          width: 100px;
          height: 50px;
        }
      }
      &:nth-child(2) {
        top: 70px;
      }
      &:nth-child(3) {
        top: 140px;
      }
      &:nth-child(5) {
        top: 70px;
      }
      &:nth-child(6) {
        top: 140px;
      }
    }
  }
}
.story-show-button-bottom-show-more {
  width: 100%;
  border: 2px solid #0b2e4e;
  padding: 10px 30px;
  border-radius: 10px;
  color: #0b2e4e;
  display: none;
  background: #fff;
  font-weight: 600;
  @media (max-width: 768px) {
    width: 96%;
    padding: 8px 24px;
    margin: auto;
  }
}
.success-stories-inner-area.all-stories {
  padding: 50px 0;
  padding-bottom: 200px;
}
.hero-button-bottom-wrap.story-button-bottom-btn {
  display: flex;
  gap: 15px;
  position: relative;
  z-index: 999;
  svg {
    path {
      fill: #0b2e4e;
    }
  }
  button.maac-portfolio-btn.hero-button-two {
    a {
      color: #0b2e4e;
    }
    &:hover {
      a {
        color: #fff;
      }
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  button.maac-portfolio-btn.demo-request-btn {
    a {
      color: #fff;
    }
    &:hover {
      a {
        color: #0b2e4e;
      }
    }
  }
}
.total-count-demo-users {
  position: absolute;
  left: 14px;
  top: 8px;
  min-width: 70px;
  padding: 10px 10px;
  border-radius: 0px 10px 10px 0px;
  color: #1b3f73;
  font-size: 20px;
  font-weight: 600;
  span {
    display: flex;
    align-items: center;
    gap: 5px;
    i {
      font-size: 20px;
      display: block;
    }
  }
}
.stories-title-left.common-title h2 {
  margin-bottom: 30px;
}
.stories-bottom-contact-area {
  padding-top: 130px;
  text-align: center;
}
