.maac-portfolio-btn {
  border: 1px solid #1b3f73;
  color: #fff;
  padding: 8px 30px;
  border-radius: 6px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  a {
    // padding: 8px 30px;
    display: inline-block;
  }
  svg {
    height: 20px;
  }
  z-index: 1;
  overflow: hidden;
  background: #1b3f73;
  color: #fff;
  &:after {
    content: "";
    position: absolute;
    width: 0%;
    height: 100%;
    transition: 0.5s;
    left: 0;
    z-index: -1;
  }
  &:hover {
    &:after {
      transition: 0.5s;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #fff;
      left: 0;
      top: 0;
      z-index: -1;
    }
    color: #1b3f73;
    svg {
      height: 20px;
      path {
        fill: #1b3f73;
      }
    }
  }
}
.hero-button-bottom-wrap.story-button-bottom-btn {
  .maac-portfolio-btn {
    padding: 0;

    a {
      padding: 8px 30px;
      display: inline-block;
    }
  }
}
h1 {
  font-size: 36px;
  font-weight: 700;
  font-family: "Myriad Pro Bold";
  line-height: 1.3;
  margin-bottom: 0;
  color: #333333;
  position: relative;
  z-index: 999;
  @media (max-width: 992px) {
    font-size: 28px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 576px) {
    font-size: 22px;
  }
}
h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 0;
  color: #333333;
  font-family: "Myriad Pro Bold";
  position: relative;
  z-index: 999;
  @media (max-width: 992px) {
    font-size: 28px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 576px) {
    font-size: 22px;
  }
}
h3 {
  color: #333333;
  font-family: "Myriad Pro Bold";
  position: relative;
  z-index: 999;
}
h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0;
  color: #333333;
  position: relative;
  z-index: 999;
  font-family: "Myriad Pro Bold";
  @media (max-width: 992px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
  @media (max-width: 576px) {
    font-size: 16px;
  }
}
h6 {
  font-weight: 600;
  position: relative;
  z-index: 999;
}
p {
  color: #666666;
  margin-bottom: 0;
  font-family: "Myriad Pro Light";
  font-size: 14px;
  line-height: 22px;
  position: relative;
  z-index: 999;
  @media (max-width: 992px) {
    font-size: 13px;
  }
  @media (max-width: 768px) {
    font-size: 13px;
  }
}
.nav-toggle-button-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
  .footer-right-social-area {
    display: flex;
    align-items: center;
    gap: 7px;

    a {
      color: #fff;
      font-size: 22px;
      i {
        display: block;
      }
    }
  }
}
.ovarlay-content {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 61%);
  top: 0;
  z-index: 9;
}
.common-title {
  position: relative;
  display: block;

  text-align: center;
  margin: auto;
  h2 {
    position: relative;
    display: inline-block;
    width: 500px;

    &:after {
      content: "";
      width: 70px;
      height: 8px;
      position: absolute;
      background: #90989f;
      bottom: -15px;
      border-radius: 5px;
      left: 215px;
    }
    @media (max-width: 768px) {
      width: 330px;
      &:after {
        content: "";
        width: 70px;
        height: 8px;
        position: absolute;
        background: #90989f;
        bottom: -15px;
        border-radius: 5px;
        left: 130px;
      }
    }
    @media (max-width: 576px) {
      width: 300px;
      &:after {
        content: "";
        width: 70px;
        height: 8px;
        position: absolute;
        background: #90989f;
        bottom: -15px;
        border-radius: 5px;
        left: 115px;
      }
    }
    @media (max-width: 400px) {
      width: 250px;
      &:after {
        content: "";
        width: 70px;
        height: 8px;
        position: absolute;
        background: #90989f;
        bottom: -15px;
        border-radius: 5px;
        left: 90px;
      }
    }
  }
}
@media (max-width: 768px) {
  .testimonials-header.common-title {
    margin-bottom: 40px !important;
  }
}
