@media (max-width: 1400px) {
  .recent-podcast-main-area-wrap .recent-podcast-single-card {
    &.top-card-2 {
      width: 230px;
      margin-left: auto;
      margin-top: 15px;
      .card-top-area {
        height: 140px;
      }
    }
    &.top-card-3 {
      width: 220px;
      margin-top: -80px;
      .card-top-area {
        height: 140px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .recent-podcast-main-area-wrap .recent-podcast-single-card {
    &.top-card-2 {
      width: 230px;
      margin-left: auto;
      margin-top: 15px;
      .card-top-area {
        height: 140px;
      }
    }
    &.top-card-3 {
      width: 220px;
      margin-top: -80px;
      .card-top-area {
        height: 140px;
      }
    }
  }
  .alumni-main-area-wrap .alumni-single-content-item {
    min-height: 400px;
  }
  .portfolio-main-area-wrap
    .portfolio-items-wrap
    .portfolio-single-item
    .portfolio-single-item-bottom-content {
    p {
      font-size: 14px;
    }
    h4 {
      margin-bottom: 12px;
      color: #333333;
      font-size: 20px;
    }
  }
}
@media (max-width: 992px) {
  .header-logo-area-left {
    display: block;
  }
  .desktop-main-logo {
    display: none;
  }
  .header-bottom-area-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .desktop-nav-items {
    display: none;
  }
  .responsive-nav-items {
    position: absolute;
    left: 0;
    width: 100%;
    display: block !important;
    background: #fff;
    top: 56px;
    ul {
      display: block !important;

      li {
        width: 100% !important;
        a {
          color: #000 !important;
        }
      }
    }
    &.nav-toggle-menu-hide {
      transform: rotateX(90deg);
      transform-origin: top;
      transition: 0.3s;
    }
    &.nav-toggle-menu-show {
      transform: rotateX(0deg);
      transform-origin: top;
      transition: 0.3s;
    }
  }
  .hero-main-area-wrap .hero-image-left-area {
    justify-content: center;
  }
  .portfolio-main-area-wrap .portfolio-items-wrap .portfolio-single-item {
    // width: 47%;
    border-radius: 10px;
    transition: 0.5s;
    &:nth-child(even) {
      position: relative;
      top: 0px;
    }
  }
  .my-achievernts-inner-wrap .achievement-statistics {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .achievements-single-item {
      text-align: center;
      width: 31%;
      margin-bottom: 25px;
    }
  }
  .success-stories-inner-area
    .success-stories-lists-area-wrap
    .success-story-single-item {
    width: 48%;

    &:nth-child(2) {
      top: 0px;
    }
    &:nth-child(3) {
      top: 0px;
    }
    &:nth-child(5) {
      top: 0px;
    }
    &:nth-child(6) {
      top: 0px;
    }
  }
  .recent-podcast-left-content {
    margin-bottom: 40px;
  }
  // .nav-toggle-button-wrap {
  //   display: block !important;
  // }
  .hero-main-area-wrap .hero-image-left-area img {
    height: 400px;
    width: auto;
  }
  .recent-podcast-main-area-wrap .recent-podcast-single-card {
    &.top-card-2 {
      width: 330px;
      margin-left: auto;
      margin-top: 15px;
      .card-top-area {
        height: 190px;
      }
    }
    &.top-card-3 {
      width: 260px;
      margin-top: -80px;
      .card-top-area {
        height: 170px;
      }
    }
  }
  section.hero-main-bg-video-area-shape .right-profile-image-area img {
    width: 100%;
  }
  .success-stories-inner-area
    .success-stories-lists-area-wrap
    .success-story-single-item
    .story-index
    h3 {
    position: absolute;
    font-size: 300px;
    color: #9e9e9e;
    font-weight: 800;
    opacity: 16%;
    bottom: -44px;
    line-height: 1;
    left: -15px;
    margin-bottom: 0;
  }
  .alumni-main-area-wrap .alumni-single-content-item {
    min-height: unset;
  }
  .your-need-main-wrap
    .your-need-item-wrap
    > *
    > *
    .your-need-single-item
    .your-need-single-item-data
    > svg {
    margin-bottom: 25px;
    cursor: pointer;
    transition: 0.3s;
    width: 40px;
    height: 40px;
  }
  .your-need-main-wrap
    .your-need-item-wrap
    > *
    > *
    .your-need-single-item
    .your-need-single-item-data
    h3 {
    margin-bottom: 15px;
    font-weight: 600 !important;
    transition: 0.3s;
    font-size: 22px;
  }
  // .major-brand-main-area-wrap .major-brand-content-area img {
  //   height: 40px;
  // }
  .portfolio-main-area-wrap
    .portfolio-items-wrap
    .swiper-wrapper
    .swiper-slide:nth-child(even) {
    position: relative;
    top: 0px;
  }
  .achievement-statistics .achievements-combo-data {
    width: 60%;
    height: 100%;
    margin-bottom: 30px;
    .achievements-single-item {
      margin-bottom: 0px;
    }
  }
}
@media (max-width: 768px) {
  .container {
    width: 96% !important;
    max-width: 100%;
  }
  .recent-podcast-main-area-wrap .recent-podcast-single-card.top-card-3 {
    width: 260px;
    margin-top: 20px;
  }
  .recent-podcast-left-content {
    padding-right: 0px;
  }
  section.hero-main-bg-video-area-shape .right-profile-image-area img {
    width: 100%;
  }
  section.hero-main-bg-video-area-shape .hero-content {
    padding-top: 10%;
    position: relative;
    z-index: 2;
    text-align: left;
  }
  .hero-main-area-wrap .hero-content-right-area > p {
    line-height: 24px;
    margin-bottom: 40px;
    padding: 0px 0%;
  }
  .hero-main-area-wrap .hero-content-right-area .hero-button-bottom-wrap {
    flex-wrap: wrap;
  }
  .my-achievernts-inner-wrap .my-achievements-title {
    padding-bottom: 60px;
  }
  .your-need-main-wrap .your-need-title-wrap {
    padding-bottom: 30px;
  }
  .your-need-main-wrap .your-need-item-wrap .hero-button-bottom-wrap {
    margin-top: 30px;
  }
  .portfolio-main-area-wrap {
    padding: 50px 0;
    padding-top: 0px;
  }
  .portfolio-main-area-wrap .portfolio-items-wrap .hero-button-bottom-wrap {
    margin-top: 30px;
  }
  .my-achievernts-inner-wrap
    .achievement-statistics
    .achievements-single-item
    .achivements-icon
    * {
    margin-bottom: 15px;
    width: 40px;
    height: 40px;
  }
  .alumni-main-area-wrap {
    padding-top: 70px;
    padding-bottom: 30px;
  }
  .stories-bottom-contact-area {
    padding-top: 50px;
    text-align: center;
  }
  .major-brand-main-area-wrap .major-brand-content-area {
    gap: 6vw;

    img {
      height: 28px;
    }
  }
  .portfolio-main-area-wrap .portfolio-items-wrap .swiper-wrapper {
    min-height: 400px;
  }
  .portfolio-main-area-wrap
    .portfolio-items-wrap
    .swiper-wrapper
    .swiper-slide:nth-child(even) {
    position: relative;
    top: 0px;
  }
  .portfolio-main-area-wrap .portfolio-items-wrap .portfolio-single-item {
    border-radius: 10px;
    transition: 0.5s;
    background: #fff;
    min-height: 320px;
    width: 100%;
  }
  .testimonials-main-area-wrap
    .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events
    .swiper-slide.swiper-slide-active {
    transform: scale(1);
    position: relative;
    top: -40px;
    transition: 0.3s;
  }
}
@media (max-width: 576px) {
  .container {
    width: 100% !important;
    max-width: 100%;
  }
  .success-stories-inner-area
    .success-stories-lists-area-wrap
    .success-story-single-item {
    width: 100%;
    min-height: 320px;
    .success-story-item-header {
      margin-bottom: 10px;
    }
  }
  .your-need-main-wrap .your-need-title-wrap {
    padding-bottom: 15px;
  }
  .story-show-button-bottom-show-more {
    display: block;
  }
  .success-stories-inner-area
    .success-stories-lists-area-wrap
    .success-story-single-item {
    &.showData {
      display: none !important;
    }
  }
  .recent-podcast-main-area-wrap .recent-podcast-single-card.top-card-2 {
    width: 240px;
    margin-left: auto;
    margin-top: 15px;
  }
  .portfolio-main-area-wrap
    .portfolio-items-wrap
    .portfolio-single-item
    .portfolio-single-item-bottom-content {
    padding: 10px 12px;
    h4 {
      font-size: 13px;
    }
    p {
      font-size: 11px;
    }
  }
  .portfolio-main-area-wrap .portfolio-items-wrap .portfolio-single-item img {
    width: 100%;
    display: inline-block;
    margin-bottom: 6px;
  }
  .portfolio-main-area-wrap .portfolio-items-wrap .hero-button-bottom-wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
  }
  .your-need-main-wrap .your-need-item-wrap .hero-button-bottom-wrap {
    justify-content: center;
    display: flex;
    margin-top: 30px;
  }
  .recent-podcast-main-area-wrap .recent-podcast-single-card .card-top-area {
    height: 150px;
  }
  .hero-main-area-wrap .hero-image-left-area img {
    width: 270px;
    height: 297px;
  }
  .header-main-area-wrap .header-top-main-area ul {
    justify-content: center;
    gap: 4vw;
  }
  .success-stories-inner-area .success-stories-title .stories-title-left h2 {
    margin-bottom: 30px;
  }
  .portfolio-main-area-wrap .portfolio-header {
    padding-bottom: 14px;
  }
  .my-achievernts-inner-wrap {
    padding: 30px 15px;
    border-radius: 10px;
    margin-top: 15px;
  }
  .alumni-main-area-wrap {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .major-brand-main-area-wrap .major-brand-content-area img {
    height: 24px;
  }
  .alumni-main-area-wrap .alumni-single-content-item h4 {
    font-size: 16px;
    margin-bottom: 7px !important;
    line-height: 24px;
  }
  .alumni-main-area-wrap .alumni-single-content-item p {
    margin-bottom: 4px;
  }
  .success-stories-inner-area
    .success-stories-lists-area-wrap
    .success-story-single-item
    div
    > {
    p {
      font-size: 12px;
    }
    h5 {
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 12px;
    }
  }
  .your-need-main-wrap
    .your-need-item-wrap
    > *
    > *
    .your-need-single-item
    .your-need-single-item-data {
    h3 {
      margin-bottom: 10px;
      font-weight: 600 !important;
      transition: 0.3s;
      font-size: 18px;
      line-height: 26px;
    }
    p {
      transition: 0.3s;
      font-size: 13px;
      line-height: 23px;
    }
  }
  .footer-main-area-wrap .footer-inner-area-wrap .footer-right-social-area a {
    display: block;
    color: #fff;
    font-size: 16px;
  }
  .hero-main-area-wrap .hero-content-right-area h1 {
    br {
      display: none;
    }
  }
  .portfolio-main-area-wrap .portfolio-items-wrap .swiper-wrapper {
    min-height: 340px;
  }
  .testimonials-main-area-wrap .testimonials-single-item {
    padding-bottom: 0;
  }
  .alumni-main-area-wrap
    .professional-accreditation
    .alumni-single-content-item.training-certification {
    min-height: 180px;
    padding: 15px 15px;
    &.showData {
      display: none;
    }
    span {
      display: block;
    }
  }
  section.hero-main-bg-video-area-shape .right-profile-image-area img {
    width: 200px;
  }
  section.hero-main-bg-video-area-shape .hero-content.hero-top-main-content {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    min-height: 63vh;
    align-items: unset;
  }
  body .shape-from-right {
    position: fixed;
    right: 0;
    top: 20%;
    z-index: -1;
    img {
      width: 200px;
    }
  }
  .stories-title-left {
    margin-bottom: 10px;
  }
  .my-achievernts-inner-wrap .achievement-statistics .achievements-single-item {
    text-align: center;
    width: 50%;
    margin-bottom: 25px;
  }
  section.hero-main-bg-video-area-shape .hero-content h2 {
    color: #fff;
    font-size: 60px !important;
    line-height: 74px !important;
    margin-bottom: 24px !important;
  }
  .nav-toggle-button-wrap .footer-right-social-area {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-top: 0;
  }
  .nav-toggle-button-wrap {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .achievement-statistics .achievements-combo-data {
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
  }
  .achievement-statistics .achievements-combo-data .achievements-single-item {
    margin-bottom: 0px;
  }
  .stories-bottom-contact-area {
    padding-top: 25px;
    text-align: center;
    .stories-left {
      a {
        display: block !important;
        width: 100% !important;
        button {
          display: block !important;
          width: 96% !important;
          margin: auto;
        }
      }
    }
  }
}
@media (max-width: 430px) {
  .portfolio-main-area-wrap .portfolio-items-wrap .portfolio-single-item {
    width: 100%;
    border-radius: 10px;
    transition: 0.5s;
  }
  .portfolio-main-area-wrap
    .portfolio-items-wrap
    .portfolio-single-item
    .portfolio-single-item-bottom-content {
    padding: 10px 12px;
    h4 {
      font-size: 18px;
    }
    p {
      font-size: 13px;
    }
  }
  .portfolio-main-area-wrap .portfolio-items-wrap .portfolio-single-item img {
    width: 100%;
    display: inline-block;
    margin-bottom: 6px;
  }
  .portfolio-main-area-wrap .portfolio-items-wrap .hero-button-bottom-wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
  }
  .your-need-main-wrap .your-need-item-wrap .hero-button-bottom-wrap {
    justify-content: center;
    display: flex;
    margin-top: 30px;
  }
  h1 {
    font-size: 20px;
    line-height: 32px;
  }
  .major-brand-main-area-wrap .major-brand-content-area img {
    height: 22px;
  }
  .testimonials-items-wrap .swiper-slide.swiper-slide-active {
    position: relative;
    top: 0px;
    transform: scale(1);
    transition: 0.3s;
  }
  .testimonials-items-wrap .swiper-wrapper {
    padding-top: 0px;
  }
  .major-brand-main-area-wrap .major-brand-content-area img {
    height: 20px;
  }
  button.nav-toggle-menu span {
    width: 100%;
    height: 2px;
    display: block;
    background: #fff;
    margin-bottom: 0;
    transition: 0.5s;
  }
  .achievement-statistics .achievements-combo-data {
    height: 100%;
    margin-bottom: 30px;
  }
}
