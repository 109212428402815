.testimonials-items-wrap .swiper-slide.swiper-slide-active {
  position: relative;
  top: -40px;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: 0.3s;
}
/* .testimonials-main-area-wrap
  .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events */
.swiper-slide.swiper-slide-active .testimonials-single-item-bottom-content {
  background: #1b3f73 !important;
}
.testimonials-items-wrap .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  padding-top: 70px;
}
.testimonials-items-wrap
  .swiper-slide.swiper-slide-active
  .testimonials-single-item-bottom-content
  p {
  margin-bottom: 25px;
  font-size: 14px;
  color: #fff;
}
