.major-brand-main-area-wrap {
  padding: 66px 0;
  background: #fff;
  .major-brand-title {
    margin-bottom: 40px;
    h2 {
      margin-bottom: 30px;
    }
  }
  .major-brand-content-area {
    display: flex;
    flex-wrap: wrap;
    gap: 4vw;
    justify-content: flex-start;
    img {
      height: 30px;
    }
    .marquee-container {
      display: flex;
      gap: 4vw;
    }
    .marquee {
      gap: 3vw !important;
      .initial-child-container {
        gap: 3vw !important;
      }
      img {
        height: 34px !important;
      }
    }
  }
  .hero-button-bottom-wrap {
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }
}
