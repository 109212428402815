//==============================
.skills-main-area-wrap-portfolio {
  padding: 60px 0;
  padding-bottom: 90px;
  .skills-inner-area {
    .experiance-section-title.common-title p {
      margin-top: 40px;
    }
    .skills-tab-data-area-wrap {
      margin-top: 40px;

      ul.mb-3.nav.nav-tabs {
        justify-content: center;
        gap: 10%;
        --bs-nav-tabs-border-width: 0px;
        --bs-nav-tabs-border-color: #dee2e6;
        --bs-nav-tabs-border-radius: 0.375rem;
        --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
        --bs-nav-tabs-link-active-color: #495057;
        --bs-nav-tabs-link-active-bg: #fff;
        --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
        border-bottom: none !important;
        li {
          button {
            color: #1b3f73;
            &.nav-link {
              position: relative;
              display: flex;
              justify-content: center;
              &.active {
                &::before {
                  position: absolute;
                  content: "";
                  width: 50px;
                  height: 3px;

                  bottom: 0;
                  background: #1b3f73;
                }
              }
            }
          }
        }
      }
      .skill-graph-single-part-area {
        position: relative;
        //==================
        .skill-bg-point-area-wrap {
          display: flex;
          justify-content: space-between;
          .single-skill-bg-point {
            width: 25%;
            border-right: 2px solid #efefef;
            min-height: 400px;
            position: relative;
            &:first-child {
              border-left: 2px solid #efefef;
            }
            p {
              position: absolute;
              bottom: -30px;
              right: -30px;
              font-weight: 600;
              @media (max-width: 767px) {
                right: 0px;
              }
            }
          }
        }
        //==================
        .skill-list-area-wrap {
          position: absolute;
          top: 70px;
          left: 0;
          width: 100%;
          .skill-single-skill-bar {
            padding: 6px;
            background: #1b3f73;
            margin-bottom: 15px;
            color: #fff;
            padding-left: 10px;
            border-radius: 0px 10px 10px 0px;
            transition: 0.4s;
            width: 0;
            font-size: 14px;
          }
        }
      }
    }
  }
}
