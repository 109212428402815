.your-need-main-wrap {
  padding: 50px 0;
  padding-top: 30px;
  @media (max-width: 992px) {
    padding-top: 50px;
  }
  .your-need-title-wrap {
    padding-bottom: 50px;
    h2 {
      margin-bottom: 30px;
    }
  }
  .your-need-item-wrap {
    > .row {
      justify-content: center;
    }
    .hero-button-bottom-wrap {
      justify-content: center;
      display: flex;
      margin-top: 60px;

      button {
        margin-bottom: 0 !important;
      }
    }
    > * > {
      a {
        margin-bottom: 0;
      }
      * {
        margin-bottom: 25px;
        .your-need-single-item {
          position: relative;
          width: 100%;
          box-shadow: 0px 0px 20px 0px #dddddd80;
          border-radius: 10px;
          padding: 7% 10%;
          background: #fff;
          height: 100%;
          overflow: hidden;

          &:after {
            background: #1b3f73;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            border-radius: 50% 50% 50% 0%;
            transform: scale(0);
            transform-origin: left bottom;
            transition: 0.3s;
          }
          &:hover {
            cursor: pointer;
            &:after {
              transform: scale(1);
              border-radius: 10px;
            }
            .your-need-single-item-data {
              svg {
                path {
                  stroke: white;
                  transition: 0.3s;
                }
              }
              h3 {
                color: #fff;
                transition: 0.3s;
              }
              p {
                color: #fff;
              }
            }
          }
          .your-need-single-item-data {
            position: relative;
            z-index: 1;

            @media (max-width: 768px) {
              h3 {
                margin-bottom: 10px;
                font-size: 20px !important;
              }
            }

            > svg {
              margin-bottom: 20px;
              cursor: pointer;
              transition: 0.3s;
              height: 40px;
              width: 40px;
              @media (max-width: 768px) {
                margin-bottom: 20px;
                margin-bottom: 20px;
                width: 40px;
                height: 40px;
              }
              @media (max-width: 576px) {
                width: 40px;
                height: 40px;
              }
            }
            p {
              transition: 0.3s;
              font-size: 14px;
              line-height: 24px;
            }
            h3 {
              margin-bottom: 10px;
              font-weight: 600 !important;
              transition: 0.3s;
              font-size: 22px;
              sup {
                font-size: 12px;
                position: relative;
                top: -15px;
              }
            }
          }
        }
      }
    }
  }
}
.modal-main-area-wrap {
  .modal-dialog {
    .modal-content {
      padding: 15px 30px 40px 30px;
      &:after {
        width: 40%;
        content: "";
        height: 15px;
        position: absolute;
        top: 0;
        left: 0;
        background: #eb2027;
      }
      &:before {
        width: 40%;
        content: "";
        height: 15px;
        position: absolute;
        bottom: 0;
        right: 0;
        background: #eb2027;
      }
      .modal-header {
        border-bottom: none;
        padding-bottom: 0;
        padding: 0;
        button.btn-close {
          position: absolute;
          right: 20px;
          top: 20px;
        }
      }
      .modal-body {
        .expertise-popup-modal-content-wrap {
          h4 {
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            margin-bottom: 10px;
          }
          p {
            font-size: 14px;
            margin-bottom: 15px;
          }
          form.contact-form-data-get {
            label {
              font-size: 14px;
            }
            ::placeholder {
              font-size: 14px;
              color: #bdbdbd;
            }
            .form-control {
              height: 40px;
              background: #f3f8ff;
              &:focus {
                box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0) !important;
              }
            }
            textarea.form-control {
              min-height: 100px;
            }
            button {
              width: 100%;
              background: #1b3f73;
              text-align: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
