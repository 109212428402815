//================================

.teaching-main-area-wrap {
  margin: 70px 0;
  .common-title {
    h2 {
      margin-bottom: 30px;
    }
  }
  .teaching-and-research-content {
    .teaching-and-research-content-item {
      background: #fafafa;
      border: 1px solid rgba(221, 221, 221, 0.4392156863);
      border-radius: 10px;
      padding: 30px;
      padding-bottom: 15px;
      min-height: 260px;
      transition: 0.3s;
      height: 100%;
      @media (max-width: 576px) {
        padding: 14px;
      }
      img {
        width: 60px;

        display: inline-block;
        margin-bottom: 6px;
      }
      svg {
        height: 40px;
        display: inline-block;
        margin-bottom: 6px;
      }
      h4 {
        font-size: 18px;
        margin-bottom: 6px;
      }

      h6 {
        font-size: 16px;
      }
      p {
        span {
          margin-bottom: 6px;
          display: block;
        }
      }
    }
  }
}
