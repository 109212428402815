.my-achievernts-inner-wrap {
  background: #efefef;
  padding: 70px 25px;
  border-radius: 10px;
  margin-top: 40px;
  min-height: 420px;
  .my-achievements-title {
    padding-bottom: 70px;
    text-align: center;
    h2 {
      color: #1b3f73;
    }
  }
  .achievement-statistics {
    // display: flex;
    // justify-content: space-between;
    .achievements-single-item {
      text-align: center;
      float: left;
      width: 20%;
      height: 100%;
      h6 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 15px;
        color: #1b3f73;
        @media (max-width: 768px) {
          font-size: 13px;
        }
      }
      h3 {
        font-size: 36px;
        font-weight: 700;
        color: #1b3f73;
        @media (max-width: 991px) {
          font-size: 28px;
        }
        @media (max-width: 768px) {
          font-size: 24px;
        }
      }
      .achivements-icon * {
        margin-bottom: 20px;
        color: #0b2e4e;
        height: 40px;
        width: 40px;
        path {
          stroke: #ffa500;
        }
      }
    }
  }
}

.achievement-statistics-single-item-wrap {
  position: relative;
  > h6 {
    position: absolute;
  }
}
.achievement-statistics {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .achievements-combo-data {
    width: 40%;
    height: 100%;
    .achievements-single-item {
      width: 50% !important;
      margin-bottom: 15px;
    }
    > h6 {
      text-align: center;
      margin-top: 15px;
    }
  }
}
.achievements-sub-content h6 {
  margin-top: 32px;
}
