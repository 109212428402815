.marketing-inner-area-wrap {
  background: #f5f9ff;
  padding: 30px;
  border-radius: 10px;
  margin: 50px 0;
  .marketin-content-area-right {
    h2 {
      // margin-bottom: 10px;
    }
    p {
      margin-bottom: 15px;
    }
    ul {
      li {
        margin-bottom: 10px;
      }
      margin-bottom: 40px;
    }
  }
  .marketin-image-area-left {
    img {
      width: 100%;
    }
  }
}
