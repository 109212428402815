.hero-main-area-wrap {
  > * > * {
    align-items: center;
  }
  .hero-image-left-area {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 420px;
      height: auto;
    }
  }
  .hero-content-right-area {
    text-align: center;
    h1 {
      margin-bottom: 15px;
    }
    > p {
      line-height: 24px;
      margin-bottom: 40px;
      padding: 0px 12%;
      > * {
        display: block;
        &:first-child {
          margin-bottom: 10px;
        }
        b {
          font-weight: 700;
          font-family: "Myriad Pro Bold";
        }
      }
    }
    .hero-button-bottom-wrap {
      display: flex;
      justify-content: center;
      gap: 25px;
      button.hero-button-two {
        color: #1b3f73;
        background: #fff;
        svg path {
          fill: #1b3f73;
        }
        &:hover {
          color: #fff;
          svg path {
            fill: #fff;
          }
          &:after {
            transition: 0.5s;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: #1b3f73;
            left: 0;
            top: 0;
            z-index: -1;
          }
        }
      }
    }
  }
}
.hero-button-bottom-wrap button.hero-button-two {
  color: #1b3f73;
  background: #fff;
  &:hover {
    color: #fff;
    border-color: #1b3f73;
    &:after {
      transition: 0.5s;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #1b3f73;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
}
//==============================================
section.hero-main-bg-video-area-shape {
  height: 90vh;
  position: relative;
  width: 100%;
  .swiper.swiper-initialized {
    height: 100%;
    span.swiper-pagination-bullet {
      height: 2px;
      width: 30px;
      opacity: 0.4;
      background: #fff;
      border-radius: 4px;
      &.swiper-pagination-bullet-active {
        background: #fff;
        opacity: 1;
      }
    }
  }
  // &::after {
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   background: rgb(0 0 0 / 61%);
  //   top: 0;
  //   z-index: 99;
  // }
  .hero-content {
    // padding-top: 10%;
    position: relative;
    z-index: 2;
    .hero-top-main-content-data-wrap {
      margin-bottom: 30px;
      h2 {
        color: #fff;
        font-size: 80px;
        line-height: 92px;
        margin-bottom: 30px;
      }
      p {
        font-size: 22px;
        color: #fff;
        font-weight: 400;
      }
    }

    &.hero-top-main-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      z-index: 9;
    }
  }
  .hero-inner-area-main-wrap {
    width: 100%;
    height: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    > * {
      height: 100%;
    }
  }
  .right-profile-image-area {
    position: absolute;
    right: 0;
    bottom: -30px;
    height: unset;
    width: 40%;
    @media (max-width: 576px) {
      width: 300px;
    }
    img {
      width: 100%;
      @media (max-width: 576px) {
        width: 100% !important;
      }
    }
  }
}
section.hero-main-bg-video-area-shape .right-profile-image-area img {
  // width: 400px;
  position: relative;
  z-index: 999;
}
.hero-main-area-wrap {
  padding: 90px 0;
  padding-bottom: 50px;
}

@media (max-width: 992px) {
  .hero-main-area-wrap .hero-content-right-area > p {
    line-height: 20px;

    padding: 0px 6%;
  }
}
