.testimonials-main-area-wrap {
  padding: 70px 0;
  padding-top: 0px;
  // .testimonials-header {
  //   margin-bottom: 50px;
  // }

  .testimonials-header.common-title {
    margin-bottom: 20px;
  }
  .testimonials-single-item-bottom-content {
    border: 1px solid #e9ecf2;
    text-align: center;
    border-radius: 10px;
    padding: 30px 20px;
    position: relative;
    background: #fff;
    svg {
      margin-bottom: 7px;
    }
    p {
      margin-bottom: 25px;
      font-size: 14px;
    }
  }
  .testimonials-single-item {
    text-align: center;
    border-radius: 10px;
    padding-bottom: 40px;

    .profile-info-bottom {
      position: relative;
      top: -30px;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-bottom: 8px;
        object-fit: cover;
      }
      h4 {
        margin-bottom: 0;
        font-size: 22px;
      }
    }
  }
  .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events {
    padding-top: 40px;
    .swiper-slide {
      transition: all 200ms linear;
      transform: scale(0.9);

      &.swiper-slide-active {
        transform: scale(1.1);
        position: relative;
        top: -40px;
        transition: 0.3s;
        .testimonials-single-item-bottom-content {
          background: #1b3f73;
          p {
            color: #fff;
          }
        }
      }
    }
  }
  @media (max-width: 450px) {
    .testimonials-header.common-title h2 {
      width: 300px;
      &:after {
        left: 117px !important;
      }
    }
  }
}
.common-title {
  h2 {
    width: 500px;
  }
}
.testimonials-items-wrap {
  span.swiper-pagination-bullet {
    height: 2px;
    width: 24px;
    opacity: 0.3;
    background: #1b3f73;
    border-radius: 4px;
    &.swiper-pagination-bullet-active {
      width: 30px;
      opacity: 1;
      transition: 0.3s;
    }
  }
}
