.recent-podcast-main-area-wrap {
  padding: 70px 0;
  // padding-top: 120px;
  .recent-podcast-single-card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 10px 30px #b6b6b633;
    .card-top-area {
      position: relative;
      height: 190px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      svg {
        position: relative;
      }
    }
    .card-bottom-area {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      h6 {
        line-height: 26px;
        font-weight: 700;
      }
    }
    &.top-card-1 {
      width: 260px;
      margin-left: 30px;
    }
    &.top-card-2 {
      width: 360px;
      margin-left: auto;
      margin-top: 15px;
    }
    &.top-card-3 {
      width: 260px;
      margin-top: -80px;
    }
  }
}
button.audio-modal-player {
  border: none;
  width: 32px;
  height: 32px;
  padding: 0px;
  position: relative;
  background: none;
  display: block;
  margin-bottom: 20px;
  transform: rotate(45deg);
  margin-left: auto;
  span {
    width: 30px;
    height: 2px;
    display: block;
    background: #000;
    position: absolute;
    &:nth-child(2) {
      transform: rotate(90deg);
    }
  }
}
.recent-podcast-left-content {
  padding-right: 150px;
  p {
    margin-bottom: 40px;
  }
}
