.alumni-main-area-wrap {
  padding-top: 70px;
  // padding-bottom: 60px;
  .alumni-title {
    margin-bottom: 40px;
    h2 {
      margin-bottom: 30px;
    }
  }
  .professional-accreditation {
    margin-top: 30px;
    .alumni-title {
      margin-bottom: 20px;
      h4 {
        font-size: 24px;
      }
    }

    .alumni-single-content-item {
      min-height: 200px;
      background: #fafafa;
      &.pashowData {
        @media (max-width: 767px) {
          display: none !important;
        }
      }
      h4 {
        margin-bottom: 0;
      }
    }
    .alumni-single-content-item.training-certification {
      display: flex;
      flex-direction: column;
      align-items: self-start;
      justify-content: space-between;
      min-height: 200px;
      padding: 25px;
      background: #f4f4f4;
      border: 1px solid #d5d5d5;
      border-radius: 10px;
      &.showData {
        display: flex;
      }
      img {
        margin-bottom: 0;
      }
    }
  }
  .alumni-single-content-item {
    background: #ffffff;
    border: 1px solid #dddddd70;
    border-radius: 10px;
    padding: 30px;
    padding-bottom: 15px;
    margin-bottom: 25px;
    min-height: 260px;
    transition: 0.3s;
    &:hover {
      box-shadow: 0px 7px 15px 0px rgb(27 63 115 / 5%);
    }
    img {
      margin-bottom: 20px;
      display: inline-block;
      height: 50px;
      @media (max-width: 360px) {
        height: 45px;
      }
    }
    h4 {
      font-size: 18px;
      margin-bottom: 10px;
      line-height: 26px;
    }
    p {
      margin-bottom: 0px;
    }
  }
}
